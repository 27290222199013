import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import Grow from '@mui/material/Grow';
import { Paper, Typography } from '@mui/material';

import Resizer from "react-image-file-resizer";

import {useState, useEffect} from 'react'

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
  
import { useNavigate, useLocation } from 'react-router-dom'
import PostFunction from '../API/postFunction';
import GetFunction from '../API/getFunction';

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'

       
export default function MapNewMark() {

  // PARAMETRAGE
  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 0    // Largeur min
  const MinHeight = 0    // Hauteur min

  const selectProfile = state => state.myProfile
  const profil = useSelector(selectProfile)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère les données cpassées en state du lien
  const {state} = useLocation();
  const { reportType, reportMark } = state

  const [newReportState, setNewReportState] = useState({
    text : "",
    newPicFile : null,
    isFetching : false,
    isResizingPic : false,
    alertObject : null,
  })

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('MapNewMark.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('MapNewMark.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
 

  let cloneMarkersArray = [...markersArray] // On clone pour ne plus avoir de lien avec le state et éviter les problème d'immutability
  let nearbyMarkers = cloneMarkersArray.sort((a,b)=> {
    let report = {
      latitude : reportMark.lat,
      longitude : reportMark.lng
    }
    let enda = {
      latitude : a.spotLatitude,
      longitude : a.spotLongitude
    }
    let endb = {
      latitude : b.spotLatitude,
      longitude : b.spotLongitude
    }
    let distancea = calculateDistance(report, enda)       
    let distanceb = calculateDistance(report, endb)   
    if (distancea < distanceb) { return -1}    
    if (distancea > distanceb) { return 1}    
    return 0    
  }).slice(0,10)

  nearbyMarkers.forEach((item,index) => {
    let start = {
      latitude : reportMark.lat,
      longitude : reportMark.lng
    }
    let end = {
      latitude : item.spotLatitude,
      longitude : item.spotLongitude
    }
    nearbyMarkers[index] = {...item, distance:calculateDistance(start,end)}
  })

  // Identification des spots proches et mise dans le state

  function calculateDistance(start,end) {
    let lat1 = start.latitude
    let lon1 = start.longitude
    let lat2 = end.latitude
    let lon2 = end.longitude
    let R = 6371  // km
    let dLat = 2*Math.PI*R*(lat2 - lat1)/360 
    let dLon = 2 * Math.PI * R * (lon2 - lon1) / 360 * Math.cos((lat1+lat2)/2/360*2*Math.PI)
    let distance = Math.sqrt(Math.pow(dLat,2)+Math.pow(dLon,2))
    return distance 
  }
  
  useEffect(() => {
    console.log('MapNewMark.js -> useEffect : chargemment dans le state de la liste des spots proches')
    if (nearbyMarkers.length >0) {
      setNewReportState(prevState => ({
        ...prevState,
        nearbyMarkers : nearbyMarkers
      }))
    }
   },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setNewReportState(prevState => ({
        ...prevState,
        isResizingPic : true
      }))
      // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Caalback function (lancée à la fin du traitelent)
            console.log('MapNewMark -> Image compressée')
            setNewReportState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('MapNewMark -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setNewReportState(prevState => ({
      ...prevState,
      newPicFile:null
    }))
  };

  const PostPicture = () => {
    if (newReportState.newPicFile) {
      return (
        <Box sx={{display : "flex", flexDirection : "column", position : "relative", marginTop : 2}}>
          <Box
            component="img"
            sx={{maxHeight : "80vh",width:"100%"}}
            src={URL.createObjectURL(newReportState.newPicFile)}
          />
          <Box sx = {{display : "flex", justifyContent : "flex-end", top : -25, right : 5, position : "absolute", zIndex : 1}}>
            {PicChangeButton()}
            {PicDeleteButton()}
          </Box>
        </Box>
      )
    }
  }

  function PicAddButton() {
    if (!newReportState.newPicFile) {
      return (
        <LoadingButton
          color="primary"
          component="label" // Paramètre indispensable pour que le bouton fonctionne comme attendu
          variant = "contained"
          type="submit"
          startIcon={<CameraAltIcon/>}
          loading={newReportState.isResizingPic}
        >
          Ajouter une photo
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (newReportState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
          type="submit"
          variant="contained"
          loading={newReportState.isResizingPic}
          sx={{margin : 1}}
        >
          <ChangeCircleIcon/>
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (newReportState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
          sx={{margin : 1}}
        >
          <CancelIcon />
        </Button>
      );
    }
  }
    
  function handleSubmitPost() {
    SendNewReport()
  };

  function handleSubmitCancel() {
    navigate("/MapPage", {replace : true})
  };

  function CancelButton() {
    return (
      <Button
        color="error"
        variant = "contained"
        startIcon={<CancelIcon />}
        onClick={handleSubmitCancel}
      >
        Annuler
      </Button>
    );
  }

  function SendButton() {
    return (
      <LoadingButton
        color="success"
        variant = "contained"
        startIcon={<SendIcon />}
        onClick={handleSubmitPost}
        disabled={(!newReportState.text)}
        loading={newReportState.isFetching}
            >
        Publier
      </LoadingButton>
    )
  }

  function SendNewReport() {
    console.log('MapNewMark.js -> Lancement API sendPost')
    setNewReportState(prevState => ({...prevState, isFetching:true}))
    
    const newReportFormData = new FormData();
    if (newReportState.newPicFile) {
    newReportFormData.append("postPicurl", newReportState.newPicFile,newReportState.newPicFile.name)
    } else {
      newReportFormData.append("postPicurl",new File([], ''))
    }
    newReportFormData.append("postAuthor", profil.pk)
    newReportFormData.append("postText", newReportState.text)

    newReportFormData.append("postSpot", 1)
    newReportFormData.append("postType", "Report")

    // Impossible de passer un objet dans un FormData
    // On passe donc chaque élément dans un append individuel
    let postReportMark = {
      reportMarkType : reportType.type,
      reportMarkLatitude : reportMark.lat,
      reportMarkLongitude : reportMark.lng,
    }

    for (let key in postReportMark) {
      newReportFormData.append(`postreportmarks_${key}`, postReportMark[key]);
    }

    // On envoie la liste des spots de publication du post
    // Une request d'un serializer ne reussit pas à lire un array.
    // On va donc plutôt lui envoyer une serie de champs avec des keys "postSpotX"
    newReportState.nearbyMarkers.forEach((spot,index) => {
      newReportFormData.append("postSpots" + index, spot.pk)
    })

    PostFunction({fetchTarget : 'sendReportMark', fetchArgument : newReportFormData, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok')  {
        console.log('MapNewMark.js -> Envoi OK')
        setNewReportState(prevState => ({...prevState, isFetching:false}))

        // Lancement animation des points gagnés
        console.log('MapNewMark -> Déclenchement animation points gagnés newMark')
        dispatch({ type : "TOGGLE_SCOREDPOINTS_ANIMATION", payload : 50})
        
        // On récupère le UserProfile pour mettre à jour les points et le statut
        GetFunction({fetchTarget : 'getUserProfile',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('MapNewMark.js -> Chargement getUserProfile dans le state Redux')
            dispatch({ type : "LOAD_MY_PROFILE", payload:response.data[0]})
          } else {
            console.log('MapNewMark.js -> Réception du profil à jour en échec')
          }
        })
        // On récupère le reportMarksArray pour mettre à jour la carte
        GetFunction({fetchTarget : 'reportMarksArray',fetchArgument : null,token : token})
        .then((response) => {
          if (response.fetchStatus === 'Ok') {
            console.log('App.js -> Chargement reportMarksArray dans le state Redux')
            dispatch({ type : "LOAD_REPORTMARKS_ARRAY", payload:response.data})
          } else {
            console.log('App.js -> Erreur dans le fetch reportMarksArray')
          }
        })
    
        navigate("/MapPage", {replace : true})
      } else {
        console.log('Feed.js -> Envoi en échec')
        setNewReportState(prevState => ({...prevState,
          isFetching:false,
          alertObject:<Alert severity="error">Erreur lors de l'envoi de la publication</Alert>
        }))
        let errorMessage = "Erreur lors de l'envoi de la publication, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function handleChange(event) {
    setNewReportState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  return (
    <Grow in={true} timeout={200}>
      <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0}}>
        {/* Fond grisé qui renvoie vers la page de la carte*/}
        <Box
          onClick = {() => {navigate("/MapPage")}} 
          sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
        />
        <Paper sx={{position : "absolute", top : {xs :80, md : 90}, bottom : 20, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24}>
          <Box sx={{
            display : "flex",
            flexDirection : "column",
            height : "100%",
            width : "100%"
            }}
          >
            <Box sx={{display : "flex", margin : 2, justifyContent : "space-between"}}>
              {CancelButton()}
              {SendButton()}
            </Box>
            <Typography variant="body1" fontWeight = "bold" color="primary" textAlign={"center"}>
              Créer un signalement
            </Typography>
            <Box sx={{display : "flex", flex : 1, flexDirection : "column", margin : 1}} overflow = "auto"> 
              <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", flexWrap:"wrap", marginBottom : 1}} >
                <Box
                  component="img"
                  sx={{
                    display: 'inline-flex',
                    maxHeight: { xs: "7vh", md: "7vh" },
                    maxWidth: { xs: "7vw", md: "7vw" },
                    marginRight : 1
                  }}
                  src={reportType.icon}
                />
                <Box sx={{ display : "flex", flexDirection : "column", alignItems: 'flex-start'}}
                >
                  <Typography
                    variant="h6"
                    color="error"
                    style={{whiteSpace: 'pre-line'}} // Pour afficher les sauts de ligne
                  >
                    Signalement : {reportType.label}
                  </Typography>
                </Box>
              </Box>
              <TextField
                fullWidth
                multiline
                label="Que voulez-vous dire ? (obligatoire)"
                id="text"
                error={!(newReportState.text)}
                onChange={(event) => handleChange(event)}
                value={newReportState.text}
                sx={{flex : 1, 
                  marginTop : 1,
                  marginBottom : 1,
                  "& .MuiInputBase-root" : {
                    height : "100%",
                    width : "100%",
                  }
                }}
                InputProps={{
                  sx: {
                    alignItems: "flex-start", // Aligne le texte en haut
                  },
                }}
              />
              {PicAddButton()}
              {PostPicture()}
            </Box>
            {newReportState.alertObject}
         </Box>
        </Paper>
      </Box>
    </Grow>
  );
}