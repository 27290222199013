import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import {useState } from 'react'
import {useDispatch, useSelector } from 'react-redux'

import { useNavigate, useLocation } from 'react-router-dom'

import BoatTypes from '../Profile/BoatTypes'

export default function RegisterUserBoat() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const boatTypes = BoatTypes()
  
  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }
  
  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);

  const handlePreviousPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterUserDescription", {state : {fromPrevious : true}})
  };

  const handleNextPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterLocation")
  };

  const handleChange = (event) => {
    let newValue = event.target.value
    if (event.target.id === "userBoatName" || event.target.id === "userBoatModel") {
      newValue = event.target.value.slice(0,20)
    } else if (event.target.id === "userBoatHarbor") {
      newValue = event.target.value.slice(0,50)
    }
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:newValue})
  };

  const handleChangeCheck = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.checked})
  };

  const handleChangeSelect = (event) => {
    setScreenRegistrationData({...screenRegistrationData,userBoatType:event.target.value})
  };

  function userBoatTypeError() {
    return (screenRegistrationData.userDisplayBoatType && screenRegistrationData.userBoatType === 0)
  }

  function userBoatNameError() {
    return (screenRegistrationData.userDisplayBoatName && (!screenRegistrationData.userBoatName || screenRegistrationData.userBoatName.trim() === ""))
  }

  function userBoatModelError() {
    return (screenRegistrationData.userDisplayBoatModel && (!screenRegistrationData.userBoatModel || screenRegistrationData.userBoatModel.trim() === ""))
  }

  function userBoatHarborError() {
    return (screenRegistrationData.userDisplayBoatHarbor && (!screenRegistrationData.userBoatHarbor || screenRegistrationData.userBoatHarbor.trim() === ""))
  }

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{display : "flex", flex : 1, marginRight : 1}}
        startIcon={<SkipPreviousIcon />}
        onClick={handlePreviousPage}
        color={"primary"}
      >
        Retour
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{display : "flex", flex : 1, marginLeft : 1}}
        startIcon={<SkipNextIcon />}
        onClick={handleNextPage}
        color={"primary"}
        disabled={userBoatTypeError() || userBoatNameError() || userBoatModelError() || userBoatHarborError()}
        >
        Suite
      </Button>
    )
  }

  function ButtonBox() {
    return(
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", width : "100%", paddingTop : 1, paddingBottom : 1, backgroundColor : "white"}} >
        {Button1()}
        {Button2()}
      </Box>
    )
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Tes navigations et ton bateau
        </Typography>
        <Typography variant={"body1"}>
          Ecris ici quelques mots sur ton support préféré (voile, moteur...) et le ou les bateau(x) sur le(s)quel(s) tu as l'habitude de naviguer, et ton port d'attache éventuel !
        </Typography>
        <TextField
          fullWidth
          id="userBoat"
          label="Parlez-nous de votre/vos bateau(x) !"
          onChange={(event) => handleChange(event)}
          value={screenRegistrationData.userBoat}
          sx={{marginTop : 1, marginBottom : 2}}
          multiline
          rows={4}
        />
        <Typography variant={"body1"}>
          Afficher sur mon profil :
        </Typography>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={screenRegistrationData.userDisplayBoatType} onChange={handleChangeCheck} color="primary" id="userDisplayBoatType"/>
          }
            label={
              <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                  Le type de mon bateau
                </Typography>
                <Select
                  id="userBoatType"
                  value={screenRegistrationData.userBoatType}
                  label="Type"
                  onChange={handleChangeSelect}
                  required = {screenRegistrationData.userDisplayBoatType}
                  disabled={!screenRegistrationData.userDisplayBoatType}
                  error={userBoatTypeError()}
                >
                  <MenuItem value = {0}>Choisissez un type</MenuItem>
                  {boatTypes.map((item,index) => {
                    return(
                      <MenuItem key={index} value = {index + 1}>{"..." + item.boatTypeName}</MenuItem>
                    )
                  })}
                </Select>
              </Box>
            }
          />
          <FormControlLabel
            control={<Checkbox checked={screenRegistrationData.userDisplayBoatName} onChange={handleChangeCheck} color="primary" id="userDisplayBoatName"/>}
            label={
              <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                  Le nom de mon bateau
                </Typography>
                <TextField
                  required = {screenRegistrationData.userDisplayBoatName}
                  sx={{width : "30%"}}
                  id="userBoatName"
                  label="Nom"
                  onChange={(event) => handleChange(event)}
                  value={screenRegistrationData.userBoatName}
                  disabled={!screenRegistrationData.userDisplayBoatName}
                  error={userBoatNameError()}
                />
            </Box>
          }
          />
          <FormControlLabel
            control={<Checkbox checked={screenRegistrationData.userDisplayBoatModel} onChange={handleChangeCheck} color="primary" id="userDisplayBoatModel"/>}
            label={
              <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                  Le modèle de mon bateau
                </Typography>
                <TextField
                  required = {screenRegistrationData.userDisplayBoatModel}
                  sx={{width : "30%"}}
                  id="userBoatModel"
                  label="Modèle"
                  onChange={(event) => handleChange(event)}
                  value={screenRegistrationData.userBoatModel}
                  disabled={!screenRegistrationData.userDisplayBoatModel}
                  error={userBoatModelError()}
                />
              </Box>
            }
          />
          <FormControlLabel
            control={<Checkbox checked={screenRegistrationData.userDisplayBoatHarbor} onChange={handleChangeCheck} color="primary" id="userDisplayBoatHarbor"/>}
            label={
              <Box sx={{display : "flex", flexDirection : "row", alignItems : "center"}}>
                <Typography variant={"body1"} sx={{display : "flex", flexGrow : 1}}>
                  Mon port d'attache
                </Typography>
                <TextField
                  required = {screenRegistrationData.userDisplayBoatHarbor}
                  sx={{width : "30%"}}
                  id="userBoatHarbor"
                  label="Port d'attache"
                  onChange={(event) => handleChange(event)}
                  value={screenRegistrationData.userBoatHarbor}
                  disabled={!screenRegistrationData.userDisplayBoatHarbor}
                  error={userBoatHarborError()}
                />
              </Box>
            }
          />
        </FormGroup>

      {ButtonBox()}
    </Box>
  </Slide>
  );
}