import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IconButton from '@mui/material/IconButton';

import {useState, useEffect} from 'react'

import GetFunction from '../API/getFunction';

import { useNavigate } from 'react-router-dom'

// DECLARATION REDUX
import { useSelector, useDispatch } from 'react-redux'
        
export default function Account() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const dispatch = useDispatch()

  const [account, setAccount] = useState({
    email : null,
    newPassword1:"",
    newPassword2:"",
    isFetching : false,
    alertObject : null
  });

  // Hook qui affiche la carte et masque les FABs à chaque mount
  useEffect(() => {
    console.log('Account.js -> Action DISPLAY_MAP dans le store')
    dispatch({ type : "DISPLAY_MAP"})
    dispatch({type : "HIDE_FABS"})
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  // Hook qui masque la carte à chaque unmount
  useEffect(() => {
    return () => {
      console.log('Account.js -> Action HIDE_MAP dans le store')
      dispatch({ type : "HIDE_MAP"})
      dispatch({type : "DISPLAY_FABS"})
    }
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  }, [])
    
  function LoadAccount() {
    setAccount(prevState => ({...prevState, isFetching:true}))
    console.log('Account.js -> Chargement API getAccount')

    GetFunction({fetchTarget : 'getAccount', fetchArgument : null, token : token})
    .then(response => {
      console.log(response)
      if(response.fetchStatus === 'Ok') {
        console.log('Account.js -> Fin chargement API getAccount')
        console.log('Account.js -> Mise à jour du statut getAccount')
        setAccount(prevState => ({...prevState, email:response.data[0].email, isFetching:false}))
      } else {
        console.log('Account.js -> Impossible de charger le compte')
        setAccount(prevState => ({...prevState, isFetching:false}))
        let errorMessage = "Impossible de charger vos identifiants, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
}

  useEffect(() => {
    console.log('EditAccount.js -> useEffect')
    LoadAccount()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  function AccountScreen() {
    const navigate = useNavigate()
    if (account.email) {
    return(
      <Slide direction="up"  in={true} timeout={200}>
        <Box sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, zIndex : 1100}}>
          {/* Fond grisé qui renvoie vers la page de la carte*/}
          <Box
            onClick = {() => {navigate("/MapPage")}} 
            sx={{ position : "absolute", top : 0, bottom : 0, left : 0, right : 0, backgroundColor : "grey", opacity : 0.5}}
          />
          <Paper sx={{position : "absolute", top : {xs :80, md : 90}, left : 20, right : 20, backgroundColor : "white", borderRadius : 1}} elevation={24} >
            {/* Croix pour fermer la fenêtre */}
            <Box sx={{position : "absolute", top : -20, right : -20, backgroundColor : "white", borderRadius : 10}} onClick = {() => {navigate("/MapPage")}}>
              <IconButton color="secondary">
                <HighlightOffIcon fontSize = "large"/>
              </IconButton>
            </Box>
            <Box sx={{
              display : "flex",
              flexDirection : "column",
              height : "100%",
              width : "100%",
              alignItems : "center"
              }}
              overflow = "auto"
            >
            <Box sx={{ mt: 3}}>
              <TextField
                fullWidth
                id="email"
                label="Adresse email"
                value={account.email}
                InputProps={{
                  readOnly: true,
                }}
                sx={{marginTop : 2, marginBottom : 1}}
                />
              <TextField
                fullWidth
                id="password"
                label="Mot de passe"
                value="********"
                type="password"
                InputProps={{
                  readOnly: true,
                }}
                sx={{marginTop : 1, marginBottom : 2}}
                />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft:2,
                marginTop : 2
              }}
            >
              <Button
                onClick={() => navigate("/EditAccount")}
                variant="contained"
                endIcon={<EditIcon />}
                sx={{margin : 2}}
              >
                Modifier
              </Button>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Slide>
    )
  } else {
    return(
      <Container>
        <CircularProgress />
      </Container>
    )
  }
}

  return (
    AccountScreen()
  );
}