import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function AboutUs() {

  function AboutUsScreen() {
    return (
      <Box backgroundColor = "white" sx={{marginTop : {xs : 8, sm : 8, md : 9}, padding : 2}}>
        <Typography variant = "h6" color={"primary"} paragraph={true}>
          Comment NautikTalk est-il né ?
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Amateur de voile, j'apprécie la déconnexion que nous apporte notre pratique, mais j'ai toujours trouvé dommage qu'il soit si difficile de discuter entre marins..
        </Typography>
        <Typography color={"primary"} paragraph={true}>
           Au port, comment parler à ses voisins, pour un conseil de bricolage, pour emprunter un outil, pour connaitre le code des sanitaires... sans devoir aller toquer à toutes les coques du port, surtout s'il est tard  ? 
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Même chose au mouillage : difficile de communiquer avec les voisins pour savoir combien de mètres de chaîne ils ont envoyé, ou s'ils pourraient me prêter un masque pour aller vérifier mon mouillage.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Et puis, toute l'année, quand je suis chez moi, je n'ai qu'une envie : me tenir informé de ce qui se passe dans mon port préféré, aider les marins qui cherchent une information sur un mouillage que je pourrais leur donner !
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Le 14 mars 2020, un bouleversement allait changer mon avenir et celui du monde : quelques jours avant le 1er confinement du COVID-19, je dois fermer mon bar au public. Commence alors une longue période pendant laquelle je n'ai rien d'autre à faire que... attendre la fin du premier confinement !
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Pour m'occuper, je me lance alors dans un projet qui me tient à coeur depuis plus de 4 ans : créer une application nous permettant à nous, les marins, de communiquer entre nous. Je n'avais jamais mené à bien ce projet, car faire développer une application par une société spécialisé était hors de mon budget.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Alors, ça y est, c'est décidé : je me lance ! Mais cette fois, j'ai le temps de faire moi-même. J'apprends les langages informatiques nécessaires, les framework, les serveurs... mais après quelques mois de développement d'une application mobile, le confinement prend fin, je dois rouvrir mon bar, et mon projet est suspendu. 
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Je continue pourtant mon projet, sur mon temps libre, en profitant de la moindre minute, mon ordi portable toujours avec moi. Dans les transports, dans la salle d'attente du médecin, le soir à la veillée... le projet avance, avec une nouvelle idée : créer une application accessible par un simple navigateur web, sans imposer à l'utilisateur d'installer une application.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Aujourd'hui, c'est chose faite : mon appli est en ligne ! Elle est gratuite, elle le sera toujours, elle ne contiendra jamais de publicité, ne divulgera jamais votre mail ni vos données, et ne vous demandera jamais votre nom ni votre numéro de carte bancaire. Elle a un seul but : faire qu'on puisse se parler, nous qui sommes à la fois si loin et si proches.
        </Typography>
        <Typography color={"primary"} paragraph={true}>
          Que cette application puisse être utile au plus grand nombre !
        </Typography>
      </Box>
    );
  }

  return(
    <AboutUsScreen/>
  )
}