import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useNavigate} from 'react-router-dom'
import { Typography } from '@mui/material';

// DECLARATION REDUX
import { useSelector } from 'react-redux'

export default function Contact() {

  const navigate = useNavigate()

  const selectChatHeadersArray = state => state.chatHeadersArray
  const chatHeadersArray = useSelector(selectChatHeadersArray)

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectMarkersArray = state => state.markersArray
  const markersArray = useSelector(selectMarkersArray)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  let bolUserId //L'ID de l'utilisateur Bol est différent dans les bases de données DEV et PROD
  if (process.env.NODE_ENV === "production" || process.env.REACT_APP_ENV === "STAGING") {
    bolUserId = 1
  } else {
    bolUserId = 1
  }

  function ChatButton() {
    return (
      <Button
        onClick={ChatWithBol}
        variant="contained"
        endIcon={<EditIcon />}
        sx={{ mb: 2 }}
        disabled={(!token || !myProfile)}
      >
        Chatter avec Bol         
      </Button>
    );
  }

  function ChatWithBol() {
    let userIndexInSubscription = chatHeadersArray.findIndex(item => (item.chatroomsubscriptions[0].subscriptionUser === bolUserId))
    let existingChatroom = chatHeadersArray[userIndexInSubscription].chatroomsubscriptions[0].subscriptionChatroom
    let nextPage = "/Chat/" + existingChatroom + "/" + bolUserId
    navigate (nextPage)
  }

  function BolSpotButton() {
    return (
      <Button
        onClick={GoToBolSpot}
        variant="contained"
        endIcon={<LocationOnIcon/>}
        sx={{ mb: 2 }}
      >
        Aller sur le spot de Bol         
      </Button>
    );
  }

  function GoToBolSpot() {
    let bolSpot
    if (markersArray) {
      bolSpot = markersArray.find(item => item.pk = 1)
    }
    navigate("/MapPage" , {state : {zoomToSearch : bolSpot}})
  }

  function BolMailLink() {
    const bolMailUser = "contact"
    const bolMailDomain = "nautictalk.com"
    const mailtoLink = `mailto:${bolMailUser}@${bolMailDomain}`

    return (
      <Link href={mailtoLink} variant = "body1" color="primary">
        {bolMailUser}@{bolMailDomain}
      </Link>
    );
  }

  return(
    <Box backgroundColor = "white" sx={{marginTop : {xs : 8, sm : 8, md : 9}, padding : 2}}>
      <Typography variant = "h6" color={"primary"} paragraph={true}>
        Comment me contacter ?
      </Typography>
      <Typography color={"primary"} paragraph={true}>
        Si vous avez déjà un compte, c'est facile ! Pour m'envoyer un message personnel, il suffit d'aller dans la messagerie de l'application, de sélectionner la conversation avec moi (Bol), et de m'écrire. 
      </Typography>
      <Box sx={{justifyContent : "center"}}>
        {ChatButton()}
      </Box>
      <Typography color={"primary"} paragraph={true}>
        Vous pouvez aussi participer à la conversation de groupe de tous les utilisateurs, qui est hébergée dans un spot spécial, à... Noisy-le-Grand, où je réside. Dasn ce spot, vous pouvez ouvrir une nouvelle conversation, et j'essaierai d'y participer autant que possible ! 
      </Typography>
      <Box sx={{justifyContent : "center"}}>
        {BolSpotButton()}
      </Box>
      <Typography color={"primary"} >
        Si vous n'avez pas encore de compte, vous pouvez me contacter par mail ici : 
      </Typography>
      <BolMailLink/>
    </Box>
  )
}