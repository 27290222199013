import {useState, useEffect, useRef } from 'react'
import GetFunction from '../API/getFunction'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

import BackButton from '../Navigation/BackButton';

import BoatTypes from '../Profile/BoatTypes'

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';


// DECLARATIONS REACT ROUTER
import { useLocation, useNavigate } from 'react-router-dom'

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'


export default function FeedMembers() {

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const boatTypes = BoatTypes()

  const [feedMembersState, setFeedMembersState] = useState({
    feedMembers: [],
    isFetching: true
  })


  const navigate = useNavigate()
  const dispatch = useDispatch()

  // On récupère le spot passés en state du lien
  const {state} = useLocation();
  const { spot } = state
  
    // pull-to-refresh : states pour conserver le point de départ + conserver la distance de pull
  const [startPoint, setStartPoint] = useState(0);
  const [pullChange, setPullChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Fonction pour forcer le refresh
  const RefreshMemberList = () => {
    // Récupération du nouveau post
    setIsRefreshing(true)
    GetFunction({fetchTarget : 'getFeedMembers', fetchArgument : spot.pk, token : token})
    .then(response => {
      setStartPoint(0);
      setPullChange(0);
      setIsRefreshing(false)
      if(response.fetchStatus === 'Ok') {
        console.log('FeedMembers.js -> Fin chargement API getFeedMembers')
        console.log('FeedMembers.js -> Mise à jour du statut getFeedMembers')
        setFeedMembersState(prevState => ({...prevState, feedMembers:response.data}))
      } else {
        console.log('FeedMembers.js -> Impossible de charger la liste des abonnés')
      }
    })
  };

  // 
  // GESTION DU PULL-TO-REFRESH
  //

  // Création des ref et des useEffect de mise à jour des ref quand les states changent
  // Parce que les listeners n'ont pas accès aux valeurs à jour des variables et des states (enclosure)
  const startPointRef = useRef();
  const pullChangeRef = useRef();
  const isRefreshingRef = useRef();

  useEffect(() => {
    startPointRef.current = startPoint
  }, [startPoint]);

  useEffect(() => {
    pullChangeRef.current = pullChange
  }, [pullChange]);
  
  useEffect(() => {
    isRefreshingRef.current = isRefreshing
  }, [isRefreshing]);

  // Création des 3 fonctions des listeners

  const pullStart = (e) => {
    // Pour empêcher de déclencher les listeners sur tous le séléments en même temps
    e.stopPropagation()
    const { screenY } = e.targetTouches[0];
    setStartPoint(screenY);
  };

  const pull = (e) => {
    e.stopPropagation()
    const touch = e.targetTouches[0];
    const { screenY } = touch;
    let pullLength = (startPointRef.current < screenY ? screenY - startPointRef.current : 0);
    
    const scrollableList = listRef.current;
    const scrollTop = scrollableList.scrollTop;
    if (scrollTop === 0 && pullLength > 0) {
      setPullChange(pullLength);
    }
  };


  // Fonction pour gérer le endPull
  const endPull = (e) => {
    e.stopPropagation()
    if (pullChangeRef.current > 100) {
      // Plusieurs listeners peuvent être déclenchés en même temps : il faut lancer l'action une seule fois
      if (isRefreshingRef.current === false) {
        RefreshMemberList()
      }
    } else {
      setStartPoint(0);
      setPullChange(0);
    }
  };

  // Déclaration des listeners

  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.removeEventListener("touchstart", pullStart, {passive : true});
      listRef.current.removeEventListener("touchmove", pull, {passive : true});
      listRef.current.removeEventListener("touchend", endPull, {passive : true});

      listRef.current.addEventListener("touchstart", pullStart, {passive : true}); // L'écran est touché
      listRef.current.addEventListener("touchmove", pull, {passive : true});       // Mouvement du doigt
      listRef.current.addEventListener("touchend", endPull, {passive : true});     // L'écran est lâché
    }
  }, [listRef.current]);
  

// Chargement de la liste des membres, lancé dans useEffect
  function LoadFeedMembers() {
    console.log('FeedMembers.js -> Chargement API getFeedMembers')
    GetFunction({fetchTarget : 'getFeedMembers', fetchArgument : spot.pk, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('FeedMembers.js -> Fin chargement API getFeedMembers')
        console.log('FeedMembers.js -> Mise à jour du statut getFeedMembers')
        setFeedMembersState(prevState => ({...prevState, feedMembers:response.data, isFetching:false}))
      } else {
        console.log('FeedMembers.js -> Impossible de charger la liste des abonnés')
        setFeedMembersState(prevState => ({...prevState, isFetching:false}))

        let errorMessage = "Impossible de charger la liste des abonnés du lieu, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function  ViewProfile(userId) {
    navigate("/OtherUserProfile/" + userId)   
  }

  function NbSubscribers() {
    if (feedMembersState.feedMembers.length !== 0) {
      return(feedMembersState.feedMembers.length)
    } else {
      return (0)
    }
  }

  function UserAvatar(item) {
    if (item.userAvatarurl) {
      return (
        <Avatar
          src={item.userAvatarurl}  
          sx={{ width: 30, height: 30 }}
        />
      )
    } else {
      return (
        <AccountCircleIcon color="primary" aria-label="upload picture" sx={{ fontSize: 30 }} />
      );
    }
  };

  function UserGlobalBadge(item) {
    if (item.userGlobalBadge) {
    return (
      <Avatar
      src={item.userGlobalBadge}  
      sx={{ width: 15, height: 15 }}
    />
      )
    } else {
    return null;
    }
  };

  useEffect(() => {
    console.log('FeedMembers.js -> useEffect')
    LoadFeedMembers()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
    },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  function otherUserBoatType(profile) {
    return(boatTypes[profile.userBoatType-1])
  }


  function FeedMembersListContent() {
    if (!feedMembersState.isFetching) {
      if (feedMembersState.feedMembers) {
        return(
          <List sx={{width : "100%"}}>
            {feedMembersState.feedMembers.map(item =>
            <ListItem disablePadding alignItems="flex-start" key={item.pk.toString()} >
              <ListItemButton
                sx={{
                  backgroundColor: "white",
                }}
                onClick={() => ViewProfile(item.pk)}
              >
                <ListItemAvatar>
                  {UserAvatar(item)}
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Box sx={{display : "flex", alignItems : "center"}}>
                      {item.userNickname}{UserGlobalBadge(item)}{item.userDisplayBoatHarbor ? " (" + item.userBoatHarbor + ")" : null}
                    </Box>
                  }
                  secondary={(item.userDisplayBoatType ? otherUserBoatType(item) + " " : null) + (item.userDisplayBoatName ? item.userBoatName + " " : null)  + (item.userDisplayBoatModel ? "(" + item.userBoatModel + ")" : null) }
                />
              </ListItemButton>
            </ListItem>
          )}
        </List>
        )
      }
    } else {
      return(
        <Box sx={{ display : "flex", flexDirection : "column"}}>
          <Skeleton variant="rounded" width={"90%%"} height={20} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%%"} height={100} sx={{padding : 3, margin : 3}}/>
          <Skeleton variant="rounded" width={"90%%"} height={100} sx={{padding : 3, margin : 3}}/>
        </Box>
      )
    }
  }

  return (
      <Grow in={true} direction="up">
        <Paper elevation={10}
            sx={{
              display : "flex",
              flexDirection : "column",
              alignItems: 'center',
              marginTop : {xs : 8, sm : 8, md : 9},
              marginRight: 1,
              marginLeft: 1,
              marginBottom : 5,
              paddingTop: 2,
              paddingRight: 2,
              paddingLeft: 2,
              paddingBottom : 2,
              backgroundColor : "white",
              borderRadius : 3,
              overflow : "auto"
            }} ref={listRef}
          >
            <BackButton/>
            {/* La boite du dessus du CircularProgress doit être à un zIndex supérieur*/}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor : "white",
                zIndex : 1001
              }}
            >
              <Typography variant="h6" color="primary" fontWeight = "bold" onClick={() => navigate("/MapPage" , {state : {zoomToSearch : spot}})} >
                {spot.spotName}
              </Typography>
              <Typography variant="body1" color="primary" onClick={() => navigate("/MapPage" , {state : {zoomToSearch : spot}})} >
                {NbSubscribers().toString()} abonné(es)
              </Typography>
            </Box>
            {/* Section avec la référence de la zone scrollable */}
            <Box sx={{display : "flex", position : "relative", flex : 1, flexDirection : "column", alignItems : "center", width : "100%"}} overflow = "auto" ref={listRef}>
              {/* Définition d'un gradient de couleurs*/}
              <svg width={0} height={0}>
                <defs>
                  <linearGradient id="my_gradient" x1="00%" y1="00%" x2="00%" y2="100%">
                  <stop offset="0%" stopColor="red" />
                   <stop offset="5%" stopColor="orange" />
                    <stop offset="50%" stopColor="yellow" />
                    <stop offset="70%" stopColor="green" />
                    <stop offset="95%" stopColor="blue" />
                    <stop offset="100%" stopColor="purple" />
                  </linearGradient>
                </defs>
              </svg>
              <CircularProgress 
                sx={{
                  position : "absolute",
                  top : -50 + pullChange/5,
                  transition : "top 0.2s linear",
                  // Le CircularProgress doit être à un zIndex jusyte en dessous de l'élément du haut, mais au dessus des élements stardard*/}
                  zIndex : 1000,
                  'svg circle': { stroke: 'url(#my_gradient)' },
                }}
              />
              {FeedMembersListContent()}
            </Box>
        </Paper>
      </Grow>
  )
}
