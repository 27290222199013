import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom' // On importe Link en l'appelant RouterLink pour ne pas avoir de confusion avec le component Link de MUI

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Link from '@mui/material/Link';

import BackButton from '../Navigation/BackButton';

import Resizer from "react-image-file-resizer";

import GetFunction from '../API/getFunction';
import PostFunction from '../API/postFunction';

import dayjs from 'dayjs'
import  'dayjs/locale/fr'
import 'dayjs/plugin/relativeTime'

// DECLARATIONS STORE
import { useSelector, useDispatch } from 'react-redux'

export default function Chat() {
  dayjs.locale('fr')

  // Compression des images
  const MaxWidth = 1500    // Largeur max
  const MaxHeight = 1500    // Hauteur max
  const Quality = 90      // Qualité (0-100)
  const MinWidth = 0    // Largeur min
  const MinHeight = 0    // Hauteur min
  
  let {chatroomId, userId} = useParams()

  const [chatState, setChatState] = useState({
    userProfile: null,
    chatMessagesContent: [],
    isFetching: true,
  })
  
  const [messageState, setMessageState] = useState({
    isSendingMessage:false,
    isResizingPic : false,
    inputText:"",
    newPicFile : null,
  })

  const selectToken = state => state.token
  const token = useSelector(selectToken)

  const selectProfile = state => state.myProfile
  const myProfile = useSelector(selectProfile)

  const dispatch = useDispatch()

  const onPicChange = (event) => {
    if (event.target.files.length > 0) { // Pour éviter le cas où l'utilisateur clique sur "Annuler" dans la fenêtre
      setMessageState({
        ...messageState,
        isResizingPic : true
      })
            // Lancement de la compression de l'image de profil
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          MaxWidth,    // Largeur max
          MaxHeight,    // Hauteur max
          "JPEG",  // Format de sortie
          Quality,      // Qualité (0-100)
          0,       // Rotation
          (uri) => {        // Callback function (lancée à la fin du traitement)
            console.log('Chat.js -> Image compressée :')
            console.log(uri)
            setMessageState(prevState => ({
              ...prevState,
              isResizingPic : false,
              newPicFile : uri
            }))
          },
          "file",   // Type de sortie
          MinWidth,       // Largeur min
          MinHeight        // Hauteur min
        );
      } catch (err) {
        console.log('Chat.js -> Echec compression image')
        let errorMessage = "Votre image n'a pas pu être traitée."
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    }
  };

  const onPicDelete = () => {
    setMessageState({
      ...messageState,
      newPicFile:null
    })
  };

  const MessagePicture = () => {
    if (messageState.newPicFile) {
      return (
        <Box
          component="img"
          sx={{
            display: 'inline-flex',
            maxHeight: { xs: "10vh", md: "10vh" },
            maxWidth: { xs: "10vw", md: "10vw" },
          }}
          src={URL.createObjectURL(messageState.newPicFile)}
        />
      )
    } else return null
  };

  const PicAddButton = () => {
    if (!messageState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="add picture" component="label"
            type="submit"
            variant="contained"
            loading={messageState.isResizingPic}
          >
          <CameraAltIcon />
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicChangeButton = () => {
    if (messageState.newPicFile) {
      return (
        <LoadingButton color="primary" aria-label="change picture" component="label"
            type="submit"
            variant="contained"
            loading={messageState.isResizingPic}
          >
          <ChangeCircleIcon/>
          <input hidden accept="image/*" type="file" onChange={onPicChange} />
        </LoadingButton>
      );
    } else return null
  }

  const PicDeleteButton = () => {
    if (messageState.newPicFile) {
      return (
        <Button color="error" aria-label="remove picture" component="label"
          type="submit"
          variant="contained"
          onClick={onPicDelete}
        >
          <CancelIcon />
        </Button>
      );
    }
  }
  

  // Chargement du profil de l'autre utilisateur, lancé dans useState
  function LoadOtherUserProfile() {
    setChatState(prevState => ({...prevState, isFetching:true}))
    console.log('Chat.js -> Chargement API otheruserprofile')

    GetFunction({fetchTarget : 'getOtherUserProfile', fetchArgument:userId, token:token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Chat.js -> Fin chargement API getOtherUserProfile')
        console.log('Chat.js -> Mise à jour du statut otherUserProfile')
        setChatState(prevState => ({...prevState, userProfile : response.data[0], isFetching : false})) 
      } else {
        console.log('Chat.js -> Impossible de charger le contenu du profil')
        setChatState(prevState => ({...prevState, isFetching : false})) 

        let errorMessage = "Impossible de trouver le profil de votre interlocuteur, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})

      }
    })
  }


  // Chargement du contenu des messages, lancé dans useState
  function LoadChatMessagesContent() {
    setChatState(prevState => ({...prevState, isFetching:true}))
    console.log('Chat.js -> Chargement API getChatMessages')

    GetFunction({fetchTarget : 'getChatMessages', fetchArgument : chatroomId, token : token})
    .then(response => {
      if(response.fetchStatus === 'Ok') {
        console.log('Chat.js -> Fin chargement API getChatMessages')
        console.log('Chat.js -> Mise à jour du statut getChatMessages')
        setChatState(prevState => ({...prevState, chatMessagesContent:response.data, isFetching:false}))
      } else {
        console.log('Chat.js -> Impossible de charger la conversation')
        setChatState(prevState => ({...prevState, isFetching:false}))
        let errorMessage = "Impossible de charger la conversation, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
    // A chaque fois qu'on charge le contenu d'un chat, les headers sont mis à jour (et ainsi les statuts Unread)
    GetFunction({fetchTarget : 'chatHeadersArray', fetchArgument : null, token : token})
    .then(response => {
      if (response.fetchStatus === 'Ok') {
        console.log('Chat.js -> Fin chargement API mise à jour chatHeadersArray')
        console.log('Chat.js -> Chargement chatHeadersArray dans le state Redux')
        dispatch({ type : "LOAD_CHAT_HEADERS_ARRAY", payload:response.data})
      } else {
        console.log('Chat.js -> Erreur du fetch : on ne fait rien')
      }
    })
  }

  useEffect(() => {
    console.log('Chat.js -> useEffect')
    LoadOtherUserProfile()
    LoadChatMessagesContent()
    // La ligne suivante supprime un warning normal avec dispatch
    //eslint-disable-next-line
  },[]  // Syntaxe pour que le hook ne soit exécuté qu'au premier render
  );   

  const authorGlobalBadge = () => {
    if (chatState.userProfile.userGlobalBadge) {
    return (
      <Avatar
      src={chatState.userProfile.userGlobalBadge}  
      sx={{ width: 30, height: 30, marginRight : 1 }}
    />
      )
    } else {
    return null;
    }
  };

  function ChatHeader() {
    let nextPage ="/OtherUserProfile/" + userId.toString()
    if (chatState.userProfile) {
      return (
        <Box sx={{display : "flex", flexDirection : "row" ,alignItems : "center", justifyContent : "center", backgroundColor : "#fdf0e9", padding : 1}}>
          <Avatar src={chatState.userProfile.userAvatarurl ? chatState.userProfile.userAvatarurl : null} sx={{ width: 40, height: 40, marginRight : 1}} />
          <Link component={RouterLink} to={nextPage} variant="subtitle2" color="primary" underline="hover" sx={{ marginRight : 1}}>
            {chatState.userProfile.userNickname}
          </Link>
          {authorGlobalBadge()}
        </Box>
      )
    } else return null
  }
  
  function DateFromNow(item) {
    var relativeTime = require('dayjs/plugin/relativeTime')
    dayjs.extend(relativeTime)
    return(dayjs(item.messageTimestamp,"YYYY-MM-DD HH:mm:ss").fromNow())
  }
  
  function TextMessageItem(item) {
    if (item.messageText) {
      return(
        <Box
          bgcolor={(item.messageAuthor === myProfile.pk ? "#f3722C" : "#277DA1")}
          sx={{ display: 'inline-flex', borderRadius : 2 }}
        > 
          <Typography style={{whiteSpace: 'pre-line'}} padding = {1} color = {(item.messageAuthor === myProfile.pk ? "white" : "white")}>
            {item.messageText}
          </Typography>
        </Box>
      )
    } else return null
  }
  
  function ImageMessageItem(item) {
    if (item.messagePicurl) {
      return(
        <Box
          component="img"
          sx={{
            display: 'inline-flex',
            maxHeight: { xs: "60vh", md: "100vh" },
            maxWidth: { xs: "70vw", md: "40vw" },
            padding : 1,
            borderRadius : 2
          }}
          bgcolor={(item.messageAuthor === myProfile.pk ? "#f3722C" : "#DDE3E8")}
          src={item.messagePicurl}
        />
      )
    } else return null
  }

  function MessageListItem(item) {
    return(
      <Box key = {item.id} sx={{display : "flex", flexDirection : "row", width : "100%", marginTop : 1}} justifyContent={(item.messageAuthor === myProfile.pk ? "flex-end" : "flex-start")}>
        <Box sx={{display : "flex", flexDirection : "column", width : "80%"}} alignItems={(item.messageAuthor === myProfile.pk ? "flex-end" : "flex-start")}>
          {TextMessageItem(item)}
          {ImageMessageItem(item)}
          <Box bgcolor="#transparent">
          <Typography fontSize={13} color={"grey"} textAlign={'center'}>
              {DateFromNow(item)}
            </Typography>
          </Box>
        </Box>
      </Box>
    )
  }
    
  function MessageList() {
    if (!chatState.isFetching) {
      if (chatState.chatMessagesContent.length>= 1) {
        return(
          <Box sx={{flex : 1,display : "flex", flexDirection : "column", marginTop : 1,overflow : "auto"}}>
            <Box sx={{display : "flex", flexDirection : "column-reverse", padding : 1}} overflow = "auto">
              {chatState.chatMessagesContent.map(item =>
                MessageListItem(item)
              )}
            </Box>
            <Box sx={{flex : 1}}/>
          </Box>
        )
      } else {
        return(
          <Box sx={{flex : 1,display : "flex", flexDirection : "column-reverse", padding : 1}} overflow = "auto">
          <Typography fontSize={20} color={"primary"} textAlign={'center'}>
              Pas encore de message dans cette conversation
          </Typography>
          </Box>
        )
      }
    } else {
      return(
        <Container component="main"
          sx={{
            backgroundColor: "#DDDDDD",
          }}
        >
          <CircularProgress />
        </Container>
      )
    }
  }

  function handleChange(event) {
      console.log('Event :', event.target.id, event.target.value);
      setMessageState(prevState => ({...prevState,[event.target.id]:event.target.value}))
  };

  function MessageInput() {
      return(
        <Box sx={{display:"flex", flexDirection:"row", backgroundColor : "#fdf0e9", alignItems : "center", padding:1}}>
          {MessagePicture()}
          <Box sx={{display : "flex", flexDirection : "column", paddingLeft:1, paddingRight : 1}}>
            {PicAddButton()}
            {PicChangeButton()}
            {PicDeleteButton()}
          </Box>
          <Box sx={{flex : 1}}>
            <TextField 
                fullWidth
                multiline
                rows={1}
                id="inputText"
                onChange={(event) => handleChange(event)}
                value={messageState.inputText}
                sx={{backgroundColor : "white"}}
            />
          </Box>
          <LoadingButton
            variant="contained"
            sx={{ margin : 1 }}
            onClick={SendMessage}
            disabled={(!messageState.inputText)&&(!messageState.newPicFile)}
            loading={messageState.isSendingMessage}
            color="success"
          >
          <SendIcon />
          </LoadingButton>
        </Box>
      )
  }

  function SendMessage() {
    console.log('Chat.js -> Lancement API sendChatMessage')
    setMessageState(prevState => ({...prevState, isSendingMessage:true}))

    const newMessageFormData = new FormData();
    if (messageState.inputText) { // On test pour ne pas passer un champs Null (devient un texte "null" avec le DataForm !)
      newMessageFormData.append("messageText", messageState.inputText)
    } else {
      newMessageFormData.append("messageText", "")
    }
    if (messageState.newPicFile) {
      newMessageFormData.append("messagePicurl", messageState.newPicFile,messageState.newPicFile.name)
    } else {
      newMessageFormData.append("messagePicurl", new File([], ''))
    }
    newMessageFormData.append("messageAuthor", 1) //Utilisé pour que le champs soit présent et avec un bon format, mais cette date sera écrasé par le user id dans l'API
    newMessageFormData.append("messageChatroom", chatroomId)
    
    PostFunction({fetchTarget : 'sendChatMessage', fetchArgument : newMessageFormData, token : token})
    .then(response => {
      setMessageState(prevState => ({...prevState, inputText:"", newPicFile : null, isSendingMessage:false}))
      if (response.fetchStatus === 'Ok')  {
        LoadChatMessagesContent()
      } else {
        console.log('Chat.js -> Echec envoi du message')
        let errorMessage = "Impossible d'envoyer le message, vérifiez votre connexion"
        dispatch({ type : "TOGGLE_ERROR_MESSAGE_SCREEN", payload:errorMessage})
      }
    })
  }

  function ChatScreen() {
    return(
      <Box sx={{display : "flex", flexDirection : "column", backgroundColor : "white"}} width="100%" height="100%"> {/* Il faut préciser des dimensions pour pouvoir fixer ensuite une box en position absolute ou pour que les flex enfants prennent toute la place*/}
        <BackButton/>
        {/* Bloc pour dépasser sous le menu */}
        <Box sx={{height: {xs :40, md : 50}, backgroundColor : "#fdf0e9"}}/>
        {ChatHeader()}
        {MessageList()}
        <Box/>
        {MessageInput()}
      </Box>
    )
  }

  return(
    ChatScreen()
  )
}