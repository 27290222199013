import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';

import {useState } from 'react'
import {useDispatch, useSelector } from 'react-redux'

import { useNavigate, useLocation } from 'react-router-dom'

export default function RegisterLocation() {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  // Récupération du state transmis par Navigate, qui contient "back" si on vient de la page suivante
  const {state} = useLocation();
  let fromPrevious = false
  if (state) {
    if (state.fromPrevious) {
      fromPrevious = true
    }
  }
  
  const selectRegistrationData = state => state.registrationData
  const registrationData = useSelector(selectRegistrationData)

  // On initialise les données du formulaire avec les données déjà saisies stockées dans le store
  const [screenRegistrationData, setScreenRegistrationData] = useState(registrationData);

  const handlePreviousPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterUserBoat", {state : {fromPrevious : true}})
  };

  const handleNextPage = () => {
    dispatch({ type : "UPDATE_REGISTRATION_DATA", payload:screenRegistrationData})
    navigate("/RegisterEnd")
  };

  const handleChangeSwitch = (event) => {
    setScreenRegistrationData({...screenRegistrationData,[event.target.id]:event.target.checked})
  };

  function Button1() {
    return(
      <Button
        variant="contained"
        sx={{display : "flex", flex : 1, marginRight : 1}}
        startIcon={<SkipPreviousIcon />}
        onClick={handlePreviousPage}
        color={"primary"}
      >
        Retour
      </Button>
    )
  }

  function Button2() {
    return(
      <Button
        variant="contained"
        sx={{display : "flex", flex : 1, marginLeft : 1}}
        startIcon={<SkipNextIcon />}
        onClick={handleNextPage}
        color={"primary"}
        >
        Suite
      </Button>
    )
  }

  function ButtonBox() {
    return(
      <Box sx={{display : "flex", flexDirection : "row", alignItems : "center", justifyContent : "space-between", width : "100%", paddingTop : 1, paddingBottom : 1, backgroundColor : "white"}} >
        {Button1()}
        {Button2()}
      </Box>
    )
  }

  return (
    <Slide in={true} direction={fromPrevious ? "right" : "left"}>
      <Box
        sx={{
          position : "fixed",
          top : 0,
          paddingTop : {xs : 9, sm : 9, md : 10},
          bottom : 0,
          left : 0, 
          right : 0,
          paddingLeft : 2,
          paddingRight : 2,
          backgroundColor : "white",
          overflow : "auto",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h5" fontWeight = "bold" color="primary">
          Partage de position
        </Typography>
        <Typography variant={"body1"}>
          En choisissant de partager votre position, vous visualisez les autres utilisateurs sur la carte et vous pouvez disloguer avec eux.
          Vous êtes également notifiés quand une publication a lieu près de votre position.
          Les autres utilisateurs pourront voir votre position, mais pas de panique ! Votre position ne sera jamais affichée lorsque vous n'êtes pas dans une zone de navigation (personne ne vous verra chez vous assis dans votre canapé !). Et les positions sont supprimées de la carte si elles ne sont pas mises à jour pendant 24h.
        </Typography>
        <FormControlLabel
          control={<Switch checked={screenRegistrationData.userPositionShare} onChange={handleChangeSwitch} color="primary" id="userPositionShare"/>}
          label={screenRegistrationData.userPositionShare ?
            <Typography fontSize={"small"} color={"primary"} textAlign={'left'}>
              Position partagée. Vous pouvez voir la position des autres utilisateurs
            </Typography>
          :
            <Typography fontSize={"small"} color={"error"} textAlign={'left'}>
              Position non partagée. Impossible de voir la position des autres utilisateurs
            </Typography>
          }
          disabled={screenRegistrationData.isRegistered}
        />
      {ButtonBox()}
    </Box>
  </Slide>
);
}